export const ENV = 'dev';  // dev | prod

export const ApiEndpoint = ENV === 'dev' ?
    'https://test-api-server.festivalofgifts.com/api'
    : 'https://ht-be-prod-umaleejccq-el.a.run.app/api';

export const EditionConstants = {
    'hindustantimes': {
        name: 'Hindustan Times',
        id: 1,
    },
    'htcity': {
        name: 'Hindustan City',
        id: 2,
    },
    'hindustan': {
        name: 'Hindustan',
        id: 3,
    },
};